<template>
  <action-card-base
    v-if="actionData"
    :title="actionData.titre"
    :text="actionData.texte"
    :cover="actionData.cover"
    :link="actionData.lien"
    :backgroundColor="backgroundColor"
    :textColor="textColor"
    :arrowColor="arrowColor"
    :buttonColor="buttonColor"
    @click="emits('click')"
    :fullWidth="fullWidth"
    ></action-card-base>
</template>

<script setup>
import ActionCardBase from './ActionCardBase.vue';

const props = defineProps({
  action: Object,
  backgroundColor: String,
  textColor: String,
  buttonColor: String,
  arrowColor: String,
  fullWidth: {
    type: Boolean,
    default: false
  }
});
const actionData = computed(() => {
  if (props?.action?.value?.data) {
    return props.action.value.data;
  }
  else if (props?.action?.data) {
    return props.action.data;
  }
  return null;
})


const emits = defineEmits(['click'])
</script>
