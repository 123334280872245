<template>
    <mks-spacer class="sidebar__section--top hide-on-mobile-tablet">
      <mks-heading tag="h3">{{ title }}</mks-heading>
      <mks-spacer spacing="m">
        <ArticleTeaser 
          v-for="article in articles" 
          :key="article._id" 
          :content="article" 
          variant="line-small"
          @click="track('Article', {id: article.builderId, slug: article.slug})">
        </ArticleTeaser>
      </mks-spacer> 
    </mks-spacer>
</template>

<script setup>
import ArticleTeaser from './Teaser.vue';

const props = defineProps({
  title: {
    type: String,
    default: 'Ça buzze'
  },
  limit: {
    type: Number,
    default: 3
  },
  weeksAgo: {
    type: Number,
    default: 12
  }
});

const articles = ref([]);

const nuxtApp = useNuxtApp();

const weeksAgo = new Date(nuxtApp.$dayjs().subtract(props.weeksAgo, 'week'));

const { data } = await useAsyncData(`buzzing-sidebar-articles-${props.limit}-${props.weeksAgo}`, () => nuxtApp.$builder.getAll('article', {
    options: {
      sort: '-count',
      limit: props.limit
    },
    query: {
      "firstPublished.$gte": weeksAgo.toISOString()
    }
  })
);

articles.value = data._value.results;
</script>


<style lang="scss" scoped>
.sidebar__section--top {
  margin-top: $space-xl;
}
</style>
