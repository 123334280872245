<template>
  <div class="sidebar-sticky-group" {...props.attributes}>
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.sidebar-sticky-group {
  position: sticky;
  top: var(--header-height);
}
</style>

